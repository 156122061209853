.nav-pills li:nth-child(1) .nav-link.active {
    background: #017BFE;
    color: white;
  }
  .nav-pills li:nth-child(2) .nav-link.active {
    background: #6C757D;
    color: white;
  }
  .nav-pills li:nth-child(3) .nav-link.active {
    background: #28A745;
    color: white;
  }
  .nav-pills li:nth-child(4) .nav-link.active{
    background: #DC3444;
    color: white;
  }